import React, { useState, useReducer } from "react";
import { Modal, Button, Form, Toast, ToastContainer } from 'react-bootstrap';
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const functions = getFunctions(getApp());
const formReducer = (state, event) => {
  if (event.reset) {
    return {
      feedback: '',
    }
  }
  return {
    ...state,
    [event.name]: event.value
  }
}

export default function SubscriptionStatus() {

  const auth = getAuth();


  const [userEmail, setuserEmail] = useState("")
  // const [premiumStatus, setPremiumStatus] = useState(false)
  const [subscriptions, setSubscrptions] = useState([])

  const [formData, setFormData] = useReducer(formReducer, {});
  const [subscription, setSubscrption] = useState(false);
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const toggleShowError = () => setShowError(!showA);

  const handleSubmit = ev => {
    ev.preventDefault()
    console.log(formData)
  }

  const handleClose = () => {
    console.log("handleClose")
    setShow(false);
    setFormData({
      reset: true
    })
  }


  const handleCancel = () => {
    const addMessage = httpsCallable(functions, 'unsubscribe');
    addMessage({ text: formData.feedback })
      .then((result) => {
        console.log(result)
        if (result.data) {
          // if (result.data.accepted.length === 1) {
          //   console.log('ACCEPTED')
          // }
          console.log(result.data)
          if (result.data.status === true) {
            toggleShowA()
          } else {
            console.log('error')
            toggleShowError()
          }
        }

      }
      )
      .catch ((error) => {
    console.log("failed : ", error);
  });
  setShow(false);
  setFormData({
    reset: true
  })
}

const handleShow = function () {
  // console.log("id", id)
  // console.log("subscriptions", subscriptions.subscriptions)
  if (show === false) {
    setShow(true)
  }
  // if(show === true){
  //   setShow(false)
  //   }

}

// const handleShow = (id) => {
//   setShow(true)
//   console.log('getting subscription', id)
// };

// const handleChange = event => {
//   setFormData({
//     name: event.target.name,
//     value: event.target.value,
//   });
// }


onAuthStateChanged(auth, (user) => {

  if (user) {

    const usrEmail = user?.email
    setuserEmail(usrEmail)

    const getSubStatus = async function () {
      console.log('auth changed ...')
      const subStatus = httpsCallable(functions, 'substatus');
      const result = await subStatus()
      let subs = []
      result.data.forEach(element => {
        const subRec = {
          "id": element.sub_id,
          "created": element.created,
          "current_period_start": element.current_period_start,
          "current_period_end": element.current_period_end,
          "canceled_at": element.canceled_at,
        }
        subs.push(subRec)
      })
      console.log('subs', subs)
      return (subs)
    }
    const mySubs = getSubStatus()
    console.log('mySubs : ', mySubs)
    mySubs.then(subs => {
      // setSubscrptions(subs)
      if (subscriptions.length === 0) {
        console.log('subs>>>>', subs)
        setSubscrptions(subs)
      }
    })
  }



})


const formatDate = (epoch) => {
  var date = new Date(epoch * 1000);
  var formattedDate = date.getUTCDate() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCFullYear()
  return formattedDate;
}

// TODO : resolve subscription length only being 1 issue

return (
  <>
    <div className="text-center pb-5 pt-5">




      {subscriptions && subscriptions.length === 1 && subscriptions.map((item, idx) => (
        <div key={item.id} className="col-lg-6 ">
          <div className="card border-0 ">
            <div className="card-body text-center py-5">

              <p>Email : {userEmail}</p>

              <p>sub : {item.id} index : {idx} length is : {subscriptions.length}</p>
              <p>Started : {formatDate(item.created)}</p>
              <p>Most Recent Subscription Month Starting : {formatDate(item.current_period_start)}</p>
              <p>Most Recent Subscription Month Ending : {formatDate(item.current_period_end)}</p>

              {item.canceled_at && <p>Canceled : {formatDate(item.canceled_at)}</p>}

            </div>
          </div>
          {/* <Button className="nextButton text-white" onClick={handleShow(item.id)}>
              Unsubscribe
            </Button> */}
        </div>

      ))}

      {subscriptions.length > 0 && (

        <Button className="nextButton text-white" onClick={handleShow}>
          Unsubscribe
        </Button>

      )}










      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <p>Cancelling subscription now will *remove* your current access to this subscription.</p>
            <p>Any remaining time before the next subscription period will be lost.</p>
            <p>This will cancel IMMEDIATELY.</p>
            <p>Do you wish to cancel now ?</p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="text-white" variant="primary" onClick={handleCancel}>
            YES - Cancel My Subscription Immediately
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer className="p-3" position={'middle-center'}>
        <Toast show={showA} onClose={toggleShowA}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Subscription is cancelled</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>Thank you for your custom. Please re-subsubscribe to use the service againfshow.</Toast.Body>
        </Toast>
      </ToastContainer>
      <ToastContainer className="p-3" position={'middle-center'}>
        <Toast show={showError} onClose={toggleShowError}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">There has been a problem trying to cancel this subscription</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>Please try again or contact our support at support@ajbc.co</Toast.Body>
        </Toast>
      </ToastContainer>


    </div>
  </>
)

}