import React from 'react'
import { Link } from 'react-router-dom'

export default function LoginButton() {

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <div className="text-center pb-5 pt-5">
        <form onSubmit={handleSubmit}>
            <Link to="/login">
          <button type="submit"  className='btn btn-outline-primary btn-lg mt-3'>Sign in / up</button>
            </Link>
        </form>
      </div>
    </>
  )
}