import React, { useState, useReducer } from 'react'
import GithubLoginButton from '../components/GithubLoginButton';
import { useHistory } from 'react-router-dom';
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification
} from 'firebase/auth'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../firebase/FirebaseClient'

import { Form, InputGroup } from "react-bootstrap";
import './Login.css'

const formReducer = (state, event) => {
    if (event.reset) {
        return {
            password: '',
            name: '',
        }
    }
    return {
        ...state,
        [event.name]: event.value
    }
}

export default function Login() {

    const history = useHistory();
    const [formData, setFormData] = useReducer(formReducer, {});
    const [submitting, setSubmitting] = useState(false);
    const [loginError, setLoginError] = useState("");
    const state = {
        button: 1
    };

    // TODO : do something with this
    console.log('submitting : ', submitting)
    
    const handleSubmit = ev => {
        ev.preventDefault()

        setSubmitting(true);
        console.log(formData)
        console.log(formData.name)
        console.log(formData.password)

        if (state.button === 1) {
            // LOGIN
            signInWithEmailAndPassword(auth, formData.name, formData.password)
                .then((cred) => {
                    console.log('user logged in : ', cred.user.email, 'verified : ', cred.user.emailVerified)
                    setSubmitting(false);
                    setLoginError("")
                    setFormData({
                        reset: true
                    })
                    history.push("/");
                })
                .catch((err) => {
                    console.log(err.message)
                    setLoginError(err.message)
                    setSubmitting(false);
                    setFormData({
                        reset: true
                    })
                })
        }
        if (state.button === 2) {
            // CREATE ACCOUNT
            createUserWithEmailAndPassword(auth, formData.name, formData.password)
                .then((cred) => {
                    console.log('user created : ', cred.user)
                    setSubmitting(false);
                    setFormData({
                        reset: true
                    })
                    console.log('attempting to set email confirmation message ...')
                    sendEmailVerification(auth.currentUser)
                        .then(() => {
                            console.log('SENT EMAIL')
                            // ...
                        }).catch((err) => {
                            console.log('ERROR sending email : ', err)
                        });
                    history.push("/");
                })
                .catch((err) => {
                    console.log(err.code)
                    setLoginError(err.code)
                    setSubmitting(false);
                    setFormData({
                        reset: true
                    })
                })
        }

        if (state.button === 3) {
            console.log(`email link reset for email ${formData.name}...`)
            sendPasswordResetEmail(auth, formData.name)
                .then(() => {
                    setLoginError(`password sent to ${formData.name} - check your email and be sure also to look at the spam folder just in case`)
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("failure : ", errorCode, errorMessage)
                    setLoginError(`failed to send password - ${errorCode}`)
                });
        }
    }




    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    return (
        <>
            <div className="row gy-3 pt-5">
                <div className="col ">
                    <div className="container-lg my-5">
                        <div className="row gy-3">
                            <div className="text-center pb-5 pt-5">
                                {loginError &&
                                    <div>{loginError}</div>
                                }
                                <form onSubmit={handleSubmit}>
                                    <InputGroup className='mb-3'>
                                        <Form.Control
                                            name='name'
                                            onChange={handleChange}
                                            value={formData.name || ''}
                                            placeholder="Email"
                                            aria-label="Email"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    <InputGroup className='mb-3'>
                                        <Form.Control
                                            type='password'
                                            name='password'
                                            onChange={handleChange}
                                            value={formData.password || ''}
                                            placeholder="Password"
                                            aria-label="Password"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    <button className='btn btn-outline-primary btn-lg mt-3 right-margin' onClick={() => (state.button = 1)} type="submit">Login</button>
                                    <button className='btn btn-outline-primary btn-lg mt-3 right-margin' onClick={() => (state.button = 2)} type="create">Create New Account</button>
                                    <button className='btn btn-outline-primary btn-lg mt-3' onClick={() => (state.button = 3)} type="create">Email Reset Link</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <GithubLoginButton />
                </div>
            </div>
        </>
    )
}
