import { db } from '../firebase/FirebaseClient'
import { collection, addDoc,onSnapshot } from "firebase/firestore";

export async function createCheckoutSession(uid) {

  console.log('procuct code is : ', process.env.REACT_APP_ONE_MONTH_PRODUCT_CODE)

  console.log('In createCheckoutSession with', {
    price: process.env.REACT_APP_ONE_MONTH_PRODUCT_CODE,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  })

  const docRef = await addDoc(collection(db, `users/${uid}/checkout_sessions`), {
    price: process.env.REACT_APP_ONE_MONTH_PRODUCT_CODE,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });
  console.log('price code is ', process.env.REACT_APP_ONE_MONTH_PRODUCT_CODE)

  onSnapshot(docRef, (snap) => {
    const { error, url } = snap.data();
    if (error) {
      console.log(`An error occured: ${error.message}`);
    }
    if (url) {
      // redirect to Stripe Checkout URL
      window.location.assign(url);
    }
  })

}