import { useHistory } from 'react-router-dom';
import { auth } from '../firebase/FirebaseClient'
import { signInWithPopup, GithubAuthProvider } from 'firebase/auth'


export default function GithubLoginButton() {

  const history = useHistory();
  const provider = new GithubAuthProvider();

  const handleSubmit = (e) => {
    e.preventDefault()

    signInWithPopup(auth, provider)
      .then((result) => {
        GithubAuthProvider.credentialFromResult(result);
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GithubAuthProvider.credentialFromError(error);
        console.log('ERROR : ', errorCode, errorMessage, email, credential)
      });
    history.push("/");
  }
  
  return (
    <>
      <div className="text-center pb-5 pt-5">
        <form onSubmit={handleSubmit}>
          <button type="submit"  className='btn btn-outline-primary btn-lg mt-3'>Sign in with GitHub</button>
        </form>
      </div>
    </>
  )
}
