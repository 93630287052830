import React from "react";

export default function About() {
  return (
    <div className="container-lg my-5">
      <div className="text-center">
        <div>About</div>
      </div>
    </div>
  );
}
