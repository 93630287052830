import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/customTheme.scss";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Logout from "./pages/Logout";
import Login from "./pages/Login";
import Subscription from "./pages/Subscription";

function App() {

  return (
    <div className="App">
      <div className="content">
        <BrowserRouter>
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/logout">
              <Logout />
            </Route>  
            <Route path="/login">
              <Login />
            </Route>                        
            <Route path="/subscription">
              <Subscription />
            </Route>                        
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
