import { configureStore } from '@reduxjs/toolkit'
import searcherReducer from './searchSlice'
import searchTextReducer from './searchTextSlice'
import blogReducer from './blogSlice'
import userStateReducer from './userStateSlice'

export const store = configureStore({
 
  reducer: {
    searchtext: searchTextReducer,
    searcher: searcherReducer,
    blog: blogReducer,
    userState: userStateReducer,
  },
})

