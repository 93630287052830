import { React } from "react";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from 'react-router-dom'
import './Navbar.css'

const Nav = () => {

    return (
        <>
            <Navbar bg="light" expand="lg">
                <div className="container-xxl">
                    <NavLink className="navbar-brand" to="/">
                        <span className="text-secondary fw-bold">
                            AJBC
                        </span>
                    </NavLink>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <ul className="navbar-nav me-2 ">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/about">About</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/logout">Sign Out</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/subscription">Subscription Status</NavLink>
                            </li>

                        </ul>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </>
    )
};

export default Nav;
    