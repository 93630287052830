import React from 'react'
import { useHistory } from 'react-router-dom';
import { auth } from '../firebase/FirebaseClient'
import { signOut } from 'firebase/auth'

export default function LogoutButton() {
  const history = useHistory();

  const logout = () => {
    signOut(auth)
      .then(() => {
        history.push("/");
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('handle submit ran ...')
    logout()
  }

  // TODO : make hover text white

  return (
    <>
      <div className="text-center pb-5 pt-5">
        <form onSubmit={handleSubmit}>

          <button type="submit" className='btn btn-outline-primary btn-lg mt-3'>Sign Out</button>
        </form>
      </div>
    </>
  )
}
