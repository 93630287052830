import UserStatus from "../components/UserStatus"
import './Home.css'
import LandingPage from "../components/LandingPage";

export default function Home() {

  return (
    <>
      <div className="container-lg my-5">
        <div className="text-center pb-5">
          <UserStatus />
          {/* {error && <p className="error">{error}</p>}
          {isPending && user && <p className="loading">Loading...</p>} */}
        </div>
      </div>
      <div className="container-lg my-5">
        <div className="row gy-3">
          <LandingPage />
        </div>
      </div>
    </>
  );
}
