import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './redux-toolkit/store'
import { Provider } from 'react-redux'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// service worker registration
// if('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('./sw.js')
//     .then((reg) => {
//       console.log('service worker is successfully registered : ', reg)
//     })
//     .catch((err) => {
//       console.log('error : failed to register service worker : ', err)
//     })
// }

// render react app
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
reportWebVitals();