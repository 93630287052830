import LoginButton from './LoginButton';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useSelector, useDispatch } from 'react-redux'
import { storeUserState } from '../redux-toolkit/userStateSlice'
import { createCheckoutSession } from "../stripe/createCheckoutSession"
import { useState } from "react";
import './userStatus.css'

export default function UserStatus() {

  const [userID, setUserID] = useState(null)
  const [premiumStatus, setPremiumStatus] = useState(false)
  const auth = getAuth();
  const user = auth.currentUser;
  const userState = useSelector((state) => state);
  const dispatch = useDispatch()

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserID(user.uid)
      if (userState.userState?.value === 'premium') {
        setPremiumStatus(true)
      } else {
        user.getIdTokenResult(true).then(function (idToken) {
          if (idToken.claims.stripeRole === 'premium') {
            dispatch(storeUserState(idToken.claims.stripeRole))
            setPremiumStatus(true)
          }
        }).catch(function (error) {
          console.log('error getting token : ', error)
        });
      }
    }
  });

  return (
    <>
      {!userID && <LoginButton />}
      {user && (
        <div>
          <span className='text-primary'>{user.email} </span>
          {!premiumStatus ? (
            <p>
              <button onClick={() => createCheckoutSession(user.uid)} className="btn btn-outline-primary btn-lg mt-3">
                Upgrade to premium {process.env.REACT_APP_ONE_MONTH_PRODUCT_CODE}
              </button>
            </p>
          ) : (
            <span className='text-primary'>| Subscribed</span>
          )}
        </div>
      )}
    </>)
}
