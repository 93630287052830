// import 'firebase/functions';
import { initializeApp, getApp } from "firebase/app";
import { enableIndexedDbPersistence, getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";



// const localEmulator=true

// const firebaseConfig = {
//   apiKey: "AIzaSyDtiJEH9vH56OK0dKgk4gVsFY0_IDYf09Y",
//   authDomain: "ajbc-visualisations.firebaseapp.com",
//   projectId: "ajbc-visualisations",
//   storageBucket: "ajbc-visualisations.appspot.com",
//   messagingSenderId: "95724728622",
//   appId: "1:95724728622:web:bd2528f9172e3d3bbd4abf"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAKchgBYDXzrUUYOdUCEI0-1HFdeYbc5KE",
  authDomain: "ajbc-app.firebaseapp.com",
  projectId: "ajbc-app",
  storageBucket: "ajbc-app.appspot.com",
  messagingSenderId: "559421880570",
  appId: "1:559421880570:web:b6a479846a9ab9263f3143",
  measurementId: "G-BV748HLEJ8"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const localEmulator = process.env.REACT_APP_LOCAL_EMULATOR

// TODO : fix windows issue with 'true' and 'false' for environment variable
// if (window.location.hostname === 'localhost' && localEmulator && app) {
if (window.location.hostname === 'localhost' && app && false) {
  console.log("testing locally -- hitting local functions");

  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "localhost", 5001);

  // firebase.firestore().settings({
  //   host: 'localhost:9999',
  //   ssl: false
  // });
}

if (process.env.NODE_ENV === 'production') {
  enableIndexedDbPersistence(db)
    .catch((err) => {
      if (err.code === 'failed-precondition') {
        console.log('persistence failed')
      } else if (err.code === 'unimplemented') {
        console.log('persistence is not available')
      }
    });
}

const auth = getAuth();

export { db, auth };
