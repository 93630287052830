import React from 'react'
import LogoutButton from '../components/LogoutButton';

export default function Logout() {
    return (
        <>

            <div className="row gy-3 pt-5">
                <div className="col ">

                    <LogoutButton />

                </div>
            </div>

        </>


    )
}
