import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    storeBlog: (state, action) => {
        state.results = action.payload;
      },
  },
})

export const { storeBlog } = blogSlice.actions

export default blogSlice.reducer

