import React from 'react'

export default function LandingPage() {
    return (
        <>
            < div className="text-center">
                <h1>LandingPage</h1>
            </div>
        </>
    )
}
